import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import firestore from "../../../static/firebase";
import { NICE_CONSTS as niceConsts } from "../../../static/constants";

// components
import FileUploader from "../../../components/FileUploader";

const IMAGE_SET_IDS = {
  "100": "5K0K5zO2NLrGsTVgvTbh",
  "110": "fKwgbgdI2QqGvxe6l2w3",
  "120": "3TrpU7dX4tBoF6L1raP4",
  "130": "zZa9xDKGClyAGTHIQCqi",
  "150": "6sguGC0PwnEqOfBq5OBV",
  "154": "njDZKwfLn1HsAZFT90Qb",
  "160": "erVYntm3wmC6PLyN53NH",
  "200": "qVQQS4Pv4xtToqdMjJC2",
  "210": "XZT9LZV5ysnnIpZeYGNe",
  "300": "IeWWmpz1SZ7XxTMFxpt7",
  "310": "av91I0gVT3PTkQ5JMYfx",
  "400": "QrGd2wr5j9oajK79iorF",
  "410": "pL37BeQJD6Pd2VoilX9B",
  "500": "VlLKh2DrAiRW1VxWHyZw",
  "502": "A860w3X3mKZROpNQKsNz",
  "505": "plwyZIavhsEbjiaULmqK",
  "511": "ghCJwv35Jfcq3snC3SPW",
  "520": "y8cJE39HvMXv0HiGCEjB",
  "530": "QjFhv8ssZCKZQVgJLmKw",
  "610": "LvohK1hN11nPNbixjDFV",
  "620": "opiR1zRk5QSQSnJnvPyO",
  "630": "4zZtUvycWgOz7eYJ1J3D"
};

class LegalUpload extends Component {
  state = {
    redirect: false,
    uploading: false,
    virecognition: "https://virecognition.visenze.com/v1/image/recognize",
    newImageSetRef: undefined,
    imageSetIds: {},
    uploadsComplete: 0,
    totalUpload: 0
  };

  createImageSets = data => {
    const { imageSetIds } = this.state;
    const imUrlIndex = data[0].indexOf("im_url");

    for (let i = 1; i < data.length; i++) {
      const imUrl = data[i][imUrlIndex];

      // im_url format ../183/100-Mens_Shoes/400402TNG0021.jpg
      // split params and remove styleCode
      let imUrlParams = imUrl.split("/");
      imUrlParams.pop();

      let details = imUrlParams.pop();
      details = details.split("-");
      let name = details
        .pop()
        .split("_")
        .join(" ");
      let departement = details.pop();
      let collection = imUrlParams.pop();

      if (!imageSetIds.hasOwnProperty(departement)) {
        firestore
          .collection("testingImageSets")
          .add({
            collection: collection,
            name: name,
            departement: departement,
            createdAt: new Date()
          })
          .then(docRef => {
            this.setState(prevState => ({
              imageSetIds: {
                ...prevState.imageSetIds,
                [departement]: docRef.id
              }
            }));
          });
      }
    }
  };

  // fetchTrademarks = async (imUrl) => {
  //   console.log(imUrl)
  //   const body = {
  //     'imUrl': imUrl
  //   }
  //   const rawResponse = await fetch('https://us-central1-gucci-dashboard.cloudfunctions.net/getTrademarks', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(body)
  //   })
  //   const content = await rawResponse.json()
  //   console.log(content)
  // }

  // uploadImage(imUrl) {
  // firestore.collection(imagesCollectionName).add({
  //   imageSetId: this.state.newImageSetRef.id,
  //   imUrl: imUrl
  // })
  // }

  handleImageDrop = async data => {
    const imUrlIndex = data[0].indexOf("im_url");
    const tagIndex = data[0].indexOf("tag");
    const scoreIndex = data[0].indexOf("score");
    this.setState({ uploading: true, totalUpload: data.length });
    const { uploadsComplete } = this.state

    let products = {};
    for (let i = 1; i < data.length; i++) {
      let imUrl = data[i][imUrlIndex];
      let tag = data[i][tagIndex];
      let score = data[i][scoreIndex];

      score = Number(Math.round(score + "e2") + "e-2").toFixed(2);
      let trademarks = {};
      if (products.hasOwnProperty(imUrl)) {
        trademarks = Object.assign(products[imUrl], { [tag]: Number(score) });
      } else {
        trademarks = Object.assign({}, { [tag]: Number(score) });
      }
      products = Object.assign(products, { [imUrl]: trademarks });
    }

    for (let productUrl in products) {
      let imUrlParams = productUrl.split("/");
      let styleCode = imUrlParams.pop();
      styleCode = styleCode.split(".").shift();
      let details = imUrlParams.pop();
      details = details.split("-");
      details.pop();
      let departement = details.pop();

      let niceDetails = niceConsts[departement];

      console.log(IMAGE_SET_IDS[departement])

      firestore
        .collection("images191")
        .add({
          imageSetId: IMAGE_SET_IDS[departement],
          niceClass: niceDetails.class,
          status: "PENDING",
          styleCode: styleCode,
          subClass: niceDetails.subClass,
          trademarks: products[productUrl],
          url: productUrl
        })
        .then(() => {
          this.setState(prevState => ({ uploadsComplete: prevState.uploadsComplete + 1 }));
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    let { redirect, uploading } = this.state;

    if (redirect === 1) {
      return <Redirect to={`/legal/review/test`} />;
    }

    return (
      <div className="grid-container full">
        {!uploading ? (
          <FileUploader dropImages={this.handleImageDrop} />
        ) : (
          <div className="grid-y">
            <div className="grid-x grid-padding-x">
              <div className="cell small-3"></div>

              <div className="cell small-6">
                <div
                  className="progress"
                  role="progressbar"
                  tabIndex="0"
                  aria-valuemin="0"
                  aria-valuetext="50 percent"
                  aria-valuemax="100"
                >
                  <span className="progress-meter">
                    {Math.round(
                      (this.state.uploadsComplete / this.state.totalUpload) *
                        100
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LegalUpload;
