// Monitor
// import MonitorHome from './Monitor/Home'
// import MonitorUpload from './Monitor/Upload'
// import MonitorReview from './Monitor/Review'

// Legal
import LegalHome from './Legal/Home'
import LegalUpload from './Legal/Upload'
import LegalReview from './Legal/Review'
import LegalAddTrademark from './Legal/AddTrademark'

// Login
import Login from "./Login"

//Search Image
import SearchTrademark from './SearchTrademark/Home'

// Not Found
import NotFound from './NotFound'

export default {
	LegalHome,
	LegalUpload,
	LegalReview,
	LegalAddTrademark,
	Login,
	// MonitorHome,
	// MonitorUpload,
	// MonitorReview,
	SearchTrademark,
	NotFound
}