import React, { Component } from "react";
import { withRouter } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import firebase from "firebase";
import routes from "./routes";
// styles
import "./App.css";
const appStyle = {};
const topBarStyle = {
  background: "#767676",
  color: "white",
};

class App extends Component {
  state = {
    loading: true,
    user: null,
  };

  componentDidMount() {
    const self = this;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.setState({ user, loading: false });
      } else {
        self.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return <></>;
    } else if (this.props.location.pathname !== "/login" && !this.state.user) {
      return <Redirect to="/login" />;
    } else if (this.props.location.pathname === "/") {
      return <Redirect to="/legal" />;
    } else if (this.props.location.pathname === "/") {
      return <Redirect to="/legal" />;
    } else if (this.props.location.pathname === "/") {
      return <Redirect to="/dropzone" />;
    }

    return (
      <div className="App" style={appStyle}>
        {this.props.location.pathname != "/login" && (
          <div className="top-bar" style={topBarStyle}>
            <div className="top-bar-left">
              <ul className="menu" style={topBarStyle}>
                <li className="menu-text">Gucci Dashboard</li>
                {/* <li><a href="#/monitor" style={topBarStyle}>Monitor</a></li> */}
                <li>
                  <a href="#/legal" style={topBarStyle}>
                    Legal
                  </a>
                </li>
                {/* <li><a href="#/dropzone" style={topBarStyle}>Dropzone</a></li> */}
              </ul>
            </div>
          </div>
        )}

        <Switch>
          <Route exact path="/monitor" component={routes.MonitorHome} />
          <Route path="/monitor/review/:id" component={routes.MonitorReview} />
          <Route path="/monitor/upload" component={routes.MonitorUpload} />

          <Route exact path="/legal" component={routes.LegalHome} />
          <Route
            path="/legal/review/:imagesCollectionName/:id"
            component={routes.LegalReview}
          />
          <Route path="/legal/upload" component={routes.LegalUpload} />
          <Route
            path="/legal/addTrademark"
            component={routes.LegalAddTrademark}
          />

          <Route path="/login" component={routes.Login} />

          <Route path="/dropzone" component={routes.SearchTrademark} />

          <Route component={routes.NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
