import React, { Component } from "react";
import firestore, { storage } from "../../../static/firebase";
import ReactModal from "react-modal";
import Select from "react-select";

//components
import LoaderSpinner from "../../../components/LoaderSpinner";
import ContainerCard from "../../../components/ContainerCard";
import Product from "../../../components/Product";
// styles
import "./index.css";

ReactModal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class LegalReview extends Component {
  imagesRef = firestore
    .collection(this.props.match.params.imagesCollectionName)
    .where("imageSetId", "==", this.props.match.params.id);
  state = {
    products: [],
    loading: true,
    tab: "PENDING",
    showModal: false,
    selectedFilter: null,
    pageNumber: 0,
    firstVisible: null,
    lastVisible: null,
    resultLimit: 25,
    trademarkFiles: {},
    trademarkLoading: true,
    trademarkScores: {},
    trademarkSelections: []
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getProducts = async (paginate = null) => {
    const {
      tab,
      selectedFilter,
      firstVisible,
      lastVisible,
      pageNumber,
      resultLimit
    } = this.state;
    this.setState({ products: [], loading: true });

    let activeImagesRef = null;
    if (selectedFilter) {
      if (selectedFilter.value === "none") {
        activeImagesRef = this.imagesRef
          .where("status", "==", tab)
          .where("noTrademarks", "==", true);
      } else {
        activeImagesRef = this.imagesRef
          .where("status", "==", tab)
          .where(`trademarks.${selectedFilter.value}`, ">", 0)
          .orderBy(`trademarks.${selectedFilter.value}`);
      }
    } else {
      activeImagesRef = this.imagesRef.where("status", "==", tab);
    }

    activeImagesRef = activeImagesRef.orderBy("styleCode");

    if (paginate === "next") {
      this.setState({ pageNumber: pageNumber + 1 });
      activeImagesRef = activeImagesRef
        .startAfter(lastVisible)
        .limit(resultLimit);
    } else if (paginate === "previous") {
      this.setState({ pageNumber: 0 });
      activeImagesRef = activeImagesRef
        .endBefore(firstVisible)
        .limit(resultLimit);
    } else {
      activeImagesRef = activeImagesRef.limit(resultLimit);
    }

    activeImagesRef.onSnapshot(querySnapshot => {
      let products = [];
      let firstVisible = querySnapshot.docs[0];
      let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      this.setState({ firstVisible, lastVisible });

      querySnapshot.forEach(doc => {
        products.push(doc);
      });
      this.setState({ products, loading: false });
    });
  };

  popCurrentProduct = id => {
    let { products } = this.state;

    let newProducts = products.filter(product => product.id !== id);

    this.setState({ products: newProducts });
  };

  createProductList = () => {
    const { products, trademarkFiles, trademarkScores } = this.state;
    let productsList = [];
    let imagesCollectionName = this.props.match.params.imagesCollectionName;

    for (let product of products) {
      productsList.push(
        <Product
          key={product.id}
          data={product.data()}
          trademarkFiles={trademarkFiles}
          trademarkScores={trademarkScores}
          id={product.id}
          popCurrentProduct={this.popCurrentProduct}
          imagesCollectionName={imagesCollectionName}
        />
      );
    }

    return productsList;
  };

  componentDidMount = () => {
    this.getProducts();

    firestore
      .collection("trademarks")
      .orderBy("name")
      .get()
      .then(querySnapshot => {
        let trademarkFiles = {};
        let trademarkScores = {};
        let trademarkSelections = [{ value: "none", label: "none" }];
        let count = 0;
        querySnapshot.forEach(async doc => {
          trademarkScores[doc.data().name] = -2;
          trademarkSelections.push({
            value: doc.data().name,
            label: doc.data().name
          });
          const url = await storage
            .ref()
            .child(`trademarks/${doc.data().path}`)
            .getDownloadURL();
          trademarkFiles[doc.data().name] = url;
          if (count === querySnapshot.docs.length - 1) {
            this.setState({ trademarkLoading: false });
          }
          count = count + 1;
        });
        this.setState({ trademarkFiles, trademarkScores, trademarkSelections });
      });
  };

  render() {
    let {
      products,
      loading,
      trademarkLoading,
      tab,
      showModal,
      selectedFilter,
      pageNumber,
      trademarkSelections
    } = this.state;

    if (loading || trademarkLoading) {
      return <LoaderSpinner />;
    }

    return (
      <div className="card-section">
        <div className="grid-x button-group">
          <div className="cell small-3">
            <button
              className={
                tab === "PENDING"
                  ? "button secondary"
                  : "button secondary hollow"
              }
              onClick={() => {
                this.setState({ tab: "PENDING" }, () => this.getProducts());
              }}
            >
              Review
            </button>
            <button
              className={
                tab === "ONHOLD"
                  ? "button secondary"
                  : "button secondary hollow"
              }
              onClick={() => {
                this.setState({ tab: "ONHOLD" }, () => this.getProducts());
              }}
            >
              On Hold
            </button>
            <button
              className={
                tab === "APPROVED"
                  ? "button secondary"
                  : "button secondary hollow"
              }
              onClick={() => {
                this.setState({ tab: "APPROVED" }, () => this.getProducts());
              }}
            >
              Approved
            </button>
          </div>

          <div className="cell small-2" />

          <div className="cell small-2">
            <Select
              styles={{
                control: base => ({
                  ...base,
                  minHeight: "45px",
                  height: "45px"
                }),
                input: base => ({
                  ...base,
                  minHeight: "35px",
                  height: "35px"
                })
              }}
              value={selectedFilter}
              onChange={selectedFilter =>
                this.setState({ selectedFilter }, () => this.getProducts())
              }
              options={trademarkSelections}
              isClearable={true}
            />
          </div>

          <div className="cell small-2" />

          <div className="cell small-2">
            <button
              disabled={pageNumber === 0}
              className="button secondary hollow"
              onClick={() => {
                this.getProducts("previous");
              }}
            >
              &laquo;
            </button>
            <button className="button primary hollow">{pageNumber + 1}</button>
            <button
              className="button secondary hollow"
              onClick={() => {
                this.getProducts("next");
              }}
            >
              &raquo;
            </button>
          </div>

          <div className="cell small-1">
            <button
              className="button primary hollow"
              onClick={this.handleOpenModal}
            >
              Help
            </button>

            <ReactModal
              isOpen={showModal}
              onRequestClose={this.handleCloseModal}
              style={customStyles}
            >
              <button onClick={this.handleCloseModal}>x</button>

              <br />

              <span>
                <h4>Tabs</h4>
                <p>
                  There are three tabs to organize images into Pending Review,
                  On Hold, and Approved.
                </p>
                <h4>Zoom Product Image</h4>
                <p>
                  Click on the product image to get a zoomed view. Click outside
                  the popup or press escape to close the window.
                </p>
                <ol>
                  <li>Use the scroll wheel to zoom in and out.</li>
                  <li>Click and drag the image to pan across the image.</li>
                </ol>
                <h4>Trademarks</h4>
                <p>Each trademark has a logo and a description.</p>
                <ol>
                  <li>
                    If the trademark was predicted, the score will be shown
                    below the logo.
                  </li>
                  <li>
                    If the trademark was predicted and then removed, "Removed"
                    will be shown below the logo.
                  </li>
                  <li>
                    If the trademark was not predicted and then added, "Added"
                    will be shown below the logo.
                  </li>
                </ol>
              </span>
            </ReactModal>
          </div>
        </div>

        {products.length > 0 ? (
          <table className="unstriped">
            <thead>
              <tr className="text-center">
                <th width="12%">Product</th>
                <th width="10%">Actions</th>
                <th width="58%">Trademarks</th>
                <th width="20%">NICE</th>
              </tr>
            </thead>

            <tbody className="tbody">{this.createProductList()}</tbody>
          </table>
        ) : (
          <ContainerCard cardSection="There aren't any images for this section." />
        )}
      </div>
    );
  }
}

export default LegalReview;
