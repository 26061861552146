import angrycat from '../assets/logos/angrycat.png'
import ape from '../assets/logos/ape.png'
import beeclasp from '../assets/logos/beeclasp.png'
import blindforlove from '../assets/logos/blindforlove.png'
import bra from '../assets/logos/bra.png'
import brb from '../assets/logos/brb.png'
import brv from '../assets/logos/brv.png'
import chime from '../assets/logos/chime.png'
import chiusuradionysus from '../assets/logos/chiusuradionysus.png'
import flora from '../assets/logos/flora.png'
import gsinglepattern from '../assets/logos/gsinglepattern.png'
import gg13 from '../assets/logos/gg13.png'
import gghorsebit02 from '../assets/logos/gghorsebit02.png'
import gg17 from '../assets/logos/gg17.png'
import gg18 from '../assets/logos/gg18.png'
import gg20 from '../assets/logos/gg20.png'
import gsquared from '../assets/logos/gsquared.png'
import guccifirma from '../assets/logos/guccifirma.png'
import guccify from '../assets/logos/guccify.png'
import guccy from '../assets/logos/guccy.png'
import herbarium from '../assets/logos/herbarium.png'
import horsebit from '../assets/logos/horsebit.png'
import l_aveugleparamour from '../assets/logos/laveugleparamour.png'
import serpente from '../assets/logos/serpente.png'
import testaditigre from '../assets/logos/testaditigre.png'
import vrv from '../assets/logos/vrv.png'
import wildtiger from '../assets/logos/wildtiger.png'

export const NEW_IMAGE_SETS_COLLECTION_NAME = 'invalidImageSets'
export const IMAGES_COLLECTION_NAME = 'invalidImages'

export const TRADEMARK_TAG_GROUP = 'gucci_trademarks'

export const DEFAULT_IMAGE_SET = 'collection_213'
export const IMAGE_SETS = [
  // {
  //   value: 'staging_191',
  //   label: 'Staging 191',
  //   imageSetsCollectionName: 'imageSets191',
  //   imagesCollectionName: 'stagingImages191',
  // },
  {
    value: 'collection_183',
    label: 'Collection 183',
    imageSetsCollectionName: 'imageSets',
    imagesCollectionName: 'images',
  },
  {
    value: 'collection_191',
    label: 'Collection 191',
    imageSetsCollectionName: 'imageSets191',
    imagesCollectionName: 'images191',
  },
  {
    value: 'collection_193',
    label: 'Collection 193',
    imageSetsCollectionName: 'imageSets193',
    imagesCollectionName: 'images193',
  },
  {
    value: 'collection_201',
    label: 'Collection 201',
    imageSetsCollectionName: 'imageSets201',
    imagesCollectionName: 'images201',
  },
  {
    value: 'collection_203',
    label: 'Collection 203',
    imageSetsCollectionName: 'imageSets203',
    imagesCollectionName: 'images203',
  },
  {
    value: 'collection_211',
    label: 'Collection 211',
    imageSetsCollectionName: 'imageSets211',
    imagesCollectionName: 'images211',
  },
  {
    value: 'collection_213',
    label: 'Collection 213',
    imageSetsCollectionName: 'imageSets213',
    imagesCollectionName: 'images213',
  }
]

export const TRADEMARK_SCORES = {
  angrycat: -2,
  ape: -2,
  beeclasp: -2,
  blindforlove: -2,
  bra: -2,
  brb: -2,
  brv: -2,
  chime: -2,
  chiusuradionysus: -2,
  flora: -2,
  gsinglepattern: -2,
  gsquared: -2,
  gg13: -2,
  gghorsebit02: -2,
  gg17: -2,
  gg18: -2,
  gg20: -2,
  guccifirma: -2,
  guccify: -2,
  guccy: -2,
  herbarium: -2,
  horsebit: -2,
  l_aveugleparamour: -2,
  serpente: -2,
  testaditigre: -2,
  vrv: -2,
  wildtiger: -2
}

export const TRADEMARK_URLS = {
  angrycat: angrycat,
  ape: ape,
  beeclasp: beeclasp,
  blindforlove: blindforlove,
  bra: bra,
  brb: brb,
  brv: brv,
  chime: chime,
  chiusuradionysus: chiusuradionysus,
  flora: flora,
  gsinglepattern: gsinglepattern,
  gsquared: gsquared,
  gg13: gg13,
  gghorsebit02: gghorsebit02,
  gg17: gg17,
  gg18: gg18,
  gg20: gg20,
  guccifirma: guccifirma,
  guccify: guccify,
  guccy: guccy,
  herbarium: herbarium,
  horsebit: horsebit,
  l_aveugleparamour: l_aveugleparamour,
  serpente: serpente,
  testaditigre: testaditigre,
  vrv: vrv,
  wildtiger: wildtiger
}

export const TRADEMARK_SELECTIONS = [
  { value: 'none', label: 'No Trademarks' },
  { value: 'angrycat', label: 'Angry Cat' },
  { value: 'ape', label: 'Ape' },
  { value: 'beeclasp', label: 'Bee Clasp' },
  { value: 'blindforlove', label: 'Blind for Love' },
  { value: 'bra', label: 'BRA' },
  { value: 'brb', label: 'BRB' },
  { value: 'brv', label: 'BRV' },
  { value: 'chime', label: 'Chime' },
  { value: 'chiusuradionysus', label: 'Chiusura Dionysus' },
  { value: 'flora', label: 'Flora' },
  { value: 'gsinglepattern', label: 'G Single Pattern' },
  { value: 'gsquared', label: 'G Squared' },
  { value: 'gg13', label: 'GG 13' },
  { value: 'gghorsebit02', label: 'GG Horsebit 02' },
  { value: 'gg17', label: 'GG 17' },
  { value: 'gg18', label: 'GG 18' },
  { value: 'gg20', label: 'GG 20' },
  { value: 'guccifirma', label: 'Gucci Firma' },
  { value: 'guccify', label: 'Guccify' },
  { value: 'herbarium', label: 'Herbarium' },
  { value: 'horsebit', label: 'Horsebit' },
  { value: 'l_aveugleparamour', label: 'L\'aveugle Par Amour' },
  { value: 'serpente', label: 'Serpente' },
  { value: 'testaditigre', label: 'Testa di Tigre' },
  { value: 'vrv', label: 'VRV' },
  { value: 'wildtiger', label: 'Wild Tiger' },
]

export const NICE_CONSTS = {
  '100': { class: 'CL 18', subClass: '' },
  '110': { class: 'CL 18', subClass: '' },
  '112': { class: 'CL 18', subClass: '' },
  '115': { class: 'CL 18', subClass: '' },
  '120': { class: 'CL 18', subClass: '' },
  '130': { class: 'CL 18', subClass: '' },
  '140': { class: 'CL 18', subClass: '' },
  '150': { class: 'CL 25', subClass: 'BELTS' },
  '154': { class: 'CL 25', subClass: 'BELTS' },
  '160': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '200': { class: 'CL 25', subClass: 'SHOES' },
  '210': { class: 'CL 25', subClass: 'SHOES' },
  '300': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '305': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '310': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '400': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '410': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '500': { class: 'CL 14', subClass: 'JEWELRY AND FASHION JEWELRY' },
  '502': { class: 'CL 14', subClass: 'JEWELRY AND FASHION JEWELRY' },
  '505': { class: 'CL 14', subClass: 'JEWELRY AND FASHION JEWELRY' },
  '507': { class: 'CL 14', subClass: 'JEWELRY AND FASHION JEWELRY' },
  '511': { class: '', subClass: '' },
  '520': { class: 'CL 14', subClass: 'WATCHES' },
  '525': { class: 'CL 14', subClass: 'WATCHES' },
  '530': { class: 'CL 9', subClass: 'EYEWEAR' },
  '540': { class: 'CL 3', subClass: '' },
  '610': { class: 'CL 18', subClass: '' },
  '620': { class: 'CL 25', subClass: 'SHOES' },
  '630': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
  '640': { class: 'CL 25', subClass: 'CLOTHING AND SILK' },
}

export const NICE_CLASSES = [
  {
    value: 'cl3',
    label: 'CL 3',
    nice_class: 'BEAUTY',
    departments: [540],
    sub_classes: [],
  },
  {
    value: 'cl9',
    label: 'CL 9',
    nice_class: 'TOTAL',
    departments: [530],
    sub_classes: [{
      value: 'eyewear',
      label: 'EYEWEAR',
      departments: [530],
    }, {
      value: 'technology',
      label: 'TECHNOLOGY',
      departments: [],
    }],
  },
  {
    value: 'cl14',
    label: 'CL 14',
    nice_class: 'TOTAL',
    departments: [500, 502, 505, 507, 520, 525],
    sub_classes: [{
      value: 'jewwlry_and_fashion_jewelry',
      label: 'JEWELRY AND FASHION JEWELRY',
      departments: [500, 502, 505, 507],
    },{
      value: 'watches',
      label: 'WATCHES',
      departments: [520, 525],
    }],
  },
  {
    value: 'cl18',
    label: 'CL 18',
    nice_class: 'TOTAL',
    departments: [100, 110, 120, 130, 112, 115, 140, 610],
    sub_classes: [],
  },
  {
    value: 'cl20',
    label: 'CL 20',
    sub_classes: [],
  },
  {
    value: 'cl21',
    label: 'CL 21',
    sub_classes: [],
  },
  {
    value: 'cl24',
    label: 'CL 24',
    sub_classes: [],
  },
  {
    value: 'cl25',
    label: 'CL 25',
    nice_class: 'TOTAL',
    departments: [150, 154, 160, 200, 210, 300, 305, 310, 400, 410, 620, 630, 640],
    sub_classes: [{
      value: 'belts',
      label: 'BELTS',
      departments: [150, 154],
    },{
      value: 'clothing_and_silk',
      label: 'CLOTHING AND SILK',
      departments: [160, 300, 305, 310, 400, 410, 630, 640],
    },{
      value: 'shoes',
      label: 'SHOES',
      departments: [200, 210, 620],
    }],
  },
  {
    value: 'cl26',
    label: 'CL 26',
    sub_classes: [],
  }
]