import React, { Component } from "react";
import { Link } from "react-router-dom";
import firestore from "../../../static/firebase";
import firebase from "firebase/app";
import {
  DEFAULT_IMAGE_SET,
  IMAGE_SETS as imagesSetsOptions
} from "../../../static/constants";
import Select from "react-select";
import request from "request";
import axios from "axios";
import { saveAs } from "file-saver";
// import { URL, URLSearchParams } from 'url'

//components
import LoaderSpinner from "../../../components/LoaderSpinner";

class Legal extends Component {
  state = {
    addTrademarkText: "Add Trademark",
    downloadReportText: "Download Report",
    loading: true,
    imageSets: [],
    selectedCollection: null
  };

  getNumPendingImages(imageSetId) {
    const { selectedCollection } = this.state;

    return new Promise(function(resolve) {
      firestore
        .collection(selectedCollection.imagesCollectionName)
        .where("imageSetId", "==", imageSetId)
        .where("status", "==", "PENDING")
        .get()
        .then(querySnapshot => {
          resolve(querySnapshot.size);
        });
    });
  }

  getNumOnHoldImages(imageSetId) {
    const { selectedCollection } = this.state;

    return new Promise(function(resolve) {
      firestore
        .collection(selectedCollection.imagesCollectionName)
        .where("imageSetId", "==", imageSetId)
        .where("status", "==", "ONHOLD")
        .get()
        .then(querySnapshot => {
          resolve(querySnapshot.size);
        });
    });
  }

  getNumApprovedImages(imageSetId) {
    const { selectedCollection } = this.state;

    return new Promise(function(resolve) {
      firestore
        .collection(selectedCollection.imagesCollectionName)
        .where("imageSetId", "==", imageSetId)
        .where("status", "==", "APPROVED")
        .get()
        .then(querySnapshot => {
          resolve(querySnapshot.size);
        });
    });
  }

  //  getAllApprovedImages() {
  //   const { selectedCollection, imageSets } = this.state

  //   return new Promise(function(resolve) {
  //     let results  = []
  //     let imageSetCount = 0
  //     imageSets.forEach(imageSet => {
  //       firestore.collection(selectedCollection.imagesCollectionName)
  //         .where('imageSetId', '==', imageSet.id)
  //         .where('status', '==', 'APPROVED')
  //         .get().then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           results.push(Object.assign({ department: `${imageSet.department} ${imageSet.name}` }, doc.data()))
  //         })
  //         imageSetCount = imageSetCount + 1
  //         if (imageSetCount === imageSets.length) {
  //           resolve(results)
  //         }
  //       })
  //     })
  //   })
  // }

  setImageTrademarksNone = () => {
    const { selectedCollection } = this.state;
    firestore
      .collection(selectedCollection.imagesCollectionName)
      .where("imageSetId", "==", "5K0K5zO2NLrGsTVgvTbh")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          let trademarksObj = doc.data().trademarks;
          let scoreArray = Object.values(trademarksObj);
          let noTrademarks = scoreArray.filter(score => score > 0);

          if (noTrademarks.length === 0) {
            firestore
              .collection(selectedCollection.imagesCollectionName)
              .doc(doc.id)
              .update({
                noTrademarks: true
              });
          } else {
            firestore
              .collection(selectedCollection.imagesCollectionName)
              .doc(doc.id)
              .update({
                noTrademarks: false
              });
          }
        });
      });
  };

  downloadReport = () => {
    const { selectedCollection, imageSets } = this.state;
    let url =
      "https://us-central1-gucci-dashboard.cloudfunctions.net/generateReport";

    this.setState({ downloadReportText: "Generating Report..." });

    const paramsToPass = {
      imageSetsCollectionName: selectedCollection.imageSetsCollectionName,
      imagesCollectionName: selectedCollection.imagesCollectionName
    };

    axios
      .get(url, {
        responseType: "arraybuffer",
        params: paramsToPass
      })
      .then(response => {
        if (
          response.headers["content-type"] !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          alert("Nothing to download.");
        } else {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          saveAs(blob, "Gucci Trademark Report.xlsx");
        }
        this.setState({ downloadReportText: "Download Report" });
      });
  };

  getImageSets() {
    const { selectedCollection } = this.state;

    return firestore
      .collection(selectedCollection.imageSetsCollectionName)
      .orderBy("department")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async doc => {
          let numPendingImages = await this.getNumPendingImages(doc.id);
          let numOnHoldImages = await this.getNumOnHoldImages(doc.id);
          let numApprovedImages = await this.getNumApprovedImages(doc.id);
          let data = Object.assign(
            {
              id: doc.id,
              numPendingImages: numPendingImages,
              numOnHoldImages: numOnHoldImages,
              numApprovedImages: numApprovedImages
            },
            doc.data()
          );
          this.setState(prevState => ({
            imageSets: [...prevState.imageSets, data]
          }));
        });
      });
  }

  handleCollectionChange = selectedCollection => {
    this.setState(
      {
        selectedCollection,
        loading: true,
        imageSets: []
      },
      () => {
        this.gatherData();
      }
    );
  };

  objectToCsv(results) {
    const headers = [
      "StyleCode",
      "View",
      "ElementLegal_1",
      "ElementLegal_2",
      "ElementLegal_3",
      "ElementLegal_4",
      "ElementLegal_5",
      "ElementLegal_6",
      "ElementLegal_7",
      "ElementLegal_8",
      "ElementLegal_9",
      "ElementLegal_10",
      "ElementLegal_11",
      "ElementLegal_12",
      "Department",
      "Classificazione di Nizza",
      "Classificazione di Nizza 2"
    ];
    const csvRows = [];

    csvRows.push(headers);

    let elementCount = 0;
    let row = [];
    results.forEach(product => {
      row = [];
      elementCount = 12;
      row.push(product.styleCode);
      row.push("");
      if (typeof product.trademarks !== "undefined") {
        for (let [trademark, score] of Object.entries(product.trademarks)) {
          if (score > 0) {
            row.push(trademark);
            elementCount = elementCount - 1;
          }
        }
      }
      for (let i = 0; i < elementCount; i++) {
        row.push("");
      }
      row.push(product.department);
      row.push(product.niceClass);
      row.push(product.subClass);

      csvRows.push(row);
    });

    return csvRows.join("\n");
  }

  download(data) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Gucci Trademark Report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getReport = async () => {
    this.setState({ downloadReportText: "Generating Report..." });
    let results = await this.getAllApprovedImages();
    if (results.length !== 0) {
      const csvData = await this.objectToCsv(results);
      this.download(csvData);
      this.setState({ downloadReportText: "Download Report" });
    } else {
      alert("There aren't any approved products to download yet.");
      this.setState({ downloadReportText: "Download Report" });
    }
  };

  gatherData = async () => {
    await this.getImageSets();
    this.setState({ loading: false });
  };

  componentDidMount = async () => {
    await this.setState({
      selectedCollection: imagesSetsOptions.find(
        element => element.value === DEFAULT_IMAGE_SET
      )
    });

    this.gatherData();
    this.setImageTrademarksNone();
  };

  render() {
    let {
      addTrademarkText,
      downloadReportText,
      loading,
      imageSets,
      selectedCollection
    } = this.state;

    if (loading) {
      return <LoaderSpinner />;
    } else if (imageSets.length <= 0) {
      return <LoaderSpinner />;
    }

    return (
      <div className="card-section">
        <div className="grid-x">
          <div className="cell small-2">
            <Select
              styles={{
                control: base => ({
                  ...base,
                  minHeight: "45px",
                  height: "45px"
                }),
                input: base => ({
                  ...base,
                  minHeight: "35px",
                  height: "35px"
                })
              }}
              value={selectedCollection}
              onChange={this.handleCollectionChange}
              options={imagesSetsOptions}
            />
          </div>
          <div className="cell small-6" />

          <div className="cell small-2">
            <Link to="/legal/addTrademark">
              <button className="button primary hollow">
                {addTrademarkText}
              </button>
            </Link>
          </div>

          <div className="cell small-2">
            <button className="button primary hollow" onClick={this.downloadReport}>
              {downloadReportText}
            </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Total Number of Images (Pending/On Hold/Approved)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {imageSets.map((imageSet, index) => {
              return (
                <tr key={index}>
                  <td align="left">
                    {" "}
                    {imageSet.department} - {imageSet.name}{" "}
                  </td>
                  <td align="left">
                    {" "}
                    {imageSet.numPendingImages +
                      imageSet.numOnHoldImages +
                      imageSet.numApprovedImages}{" "}
                    ( {imageSet.numPendingImages} / {imageSet.numOnHoldImages} /{" "}
                    {imageSet.numApprovedImages} ){" "}
                  </td>
                  <td>
                    <Link
                      to={`/legal/review/${selectedCollection.imagesCollectionName}/${imageSet.id}`}
                    >
                      Review
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Legal;
