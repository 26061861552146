import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import csv from 'csv'

// styles
import './index.css'

class FileUploader extends Component {
  state = {
    errorMsg: ''
  }
  verifyFile(data) {
    const imUrlIndex = data[0].indexOf('im_url')
    if (imUrlIndex < 0) {
      this.setState({ errorMsg: 'Please ensure im_url exists in the CSV file.'})
      return false
    }
  }

  onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        if (!this.verifyFile(data)) {
          this.props.dropImages(data)
        }
      })
    }

    reader.readAsBinaryString(file);
  }

  render() {
    const { errorMsg } = this.state 

    return (
      <div>
        <Dropzone
          className="dropzone"
          onDrop={(files) => this.onDrop(files)}
        >
          <div>Drag and drop a .csv file here, or click to select a file to upload.</div>
        </Dropzone>

        {errorMsg && 
          <div className="grid-x error-msg">
            <div className="callout alert">
              <p>{errorMsg}</p>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default FileUploader
