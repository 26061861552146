import React, { Component } from "react";
import firestore from "../../static/firebase";
import placeholder from "../../assets/icons/placeholder.png";

// constants
// styles
import "./index.css";

class LegalReview extends Component {
  state = {
    data: {},
    trademarkFiles: {}
  };

  sortTrademarkScores(trademarks) {
    let keysSorted = Object.keys(trademarks).sort(function(a, b) {
      return trademarks[b] - trademarks[a];
    });

    let trademarksSorted = {};
    for (let key of keysSorted) {
      trademarksSorted[key] = trademarks[key];
    }

    return trademarksSorted;
  }

  handleToggle(e, trademark, score) {
    e.preventDefault();
    let { productId, imagesCollectionName } = this.props;
    let scoreNew = undefined;

    // convert from not predicted to added
    if (score <= -2) {
      scoreNew = -1 * score;
      // convert from removed to predicted
    } else if (score >= -1 && score < 0) {
      scoreNew = -1 * score;
      // convert from predicted to removed
    } else if (score > 0 && score <= 1) {
      scoreNew = -1 * score;
      // convert from added to not predicted
    } else if (score > 2) {
      scoreNew = -1 * score;
    } else {
      scoreNew = -2;
    }

    this.setState(
      prevState => ({
        data: { ...prevState.data, [trademark]: scoreNew }
      }),
      () => {
        firestore
          .collection(imagesCollectionName)
          .doc(productId)
          .update({ trademarks: this.state.data })
          .then(() => {
            let trademarksObj = this.state.data;
            let scoreArray = Object.values(trademarksObj);
            let noTrademarks = scoreArray.filter(score => score > 0);

            if (noTrademarks.length === 0) {
              firestore
                .collection(imagesCollectionName)
                .doc(productId)
                .update({
                  noTrademarks: true
                });
            } else {
              firestore
                .collection(imagesCollectionName)
                .doc(productId)
                .update({
                  noTrademarks: false
                });
            }
          });
      }
    );
  }

  createTrademarkList() {
    let { data } = this.state;
    let trademarks = Object.assign({}, this.props.trademarkScores, data);
    let trademarksSorted = this.sortTrademarkScores(trademarks);
    let entries = Object.entries(trademarksSorted);
    let trademarkList = [];

    for (let [trademark, score] of entries) {
      trademarkList.push(
        <div
          className="cell small-1 tm-cell"
          style={{ width: "6.5%" }}
          key={trademark}
        >
          <div className="container">
            <img
              className={score >= -1 ? "image" : "image faded"}
              width="70%"
              src={
                this.props.trademarkFiles[trademark]
                  ? this.props.trademarkFiles[trademark]
                  : placeholder
              }
              alt={trademark}
              onClick={e => {
                this.handleToggle(e, trademark, score);
              }}
            />

            {score >= -1 && score < 0 && (
              <div className="score">
                <div className="score reject">Removed</div>
              </div>
            )}

            {score > 0 && score <= 1 && (
              <div className="score">
                <div className="score accept">
                  {Math.round(score.toFixed(2) * 100)}%
                </div>
              </div>
            )}

            {score === 2 && (
              <div className="score">
                <div className="score accept">Added</div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return trademarkList;
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: data });
  }

  render() {
    return this.createTrademarkList();
  }
}

export default LegalReview;
