import React from 'react'

// styles
import './index.css'

const ContainerCard = (props) => {
  return (
    <div className="grid-container fluid text-center">
      <div className="grid-x">
        <div className="cell small-2"></div>

        <div className="cell small-8">
          <div className="card">
            {props.cardDivider &&
              <div className="card-divider">
                <h4>{props.cardDivider}</h4>
              </div>
            }

            {props.cardSection &&
              <div className="card-section">
                <h6>{props.cardSection}</h6>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContainerCard;
