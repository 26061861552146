import React, { Component } from "react";
import firebase from "firebase";
import { Redirect } from "react-router-dom";

class LegalUpload extends Component {
  state = {
    email: "",
    password: "",
    error: null,
    toHome: false,
  };

  componentDidMount() {
    const self = this;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.setState({ toHome: true });
      }
    });
  }

  signInWithEmailAndPasswordHandler(event, email, password) {
    event.preventDefault();
    const self = this;

    firebase
      .auth()
      .signInWithEmailAndPassword(`${email}@visenze.com`, password)
      .then(() => {
        self.setState({ toHome: true });
      })
      .catch(() => {
        this.setState({ error: "Error signing in with password and email!" });
      });
  }

  onChangeHandler(event) {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      this.setState({ email: value });
    } else if (name === "userPassword") {
      this.setState({ password: value });
    }
  }

  render() {
    let { email, password, error, toHome } = this.state;

    if (toHome === true) {
      return <Redirect to="/" />;
    }

    return (
      <div className="mt-8">
        <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>

        <div className="grid-x">
          <div className="cell small-4" />
          <div className="cell small-4">
            <form className="">
              <label htmlFor="userEmail" className="block">
                Username:
              </label>
              <input
                type="email"
                className="my-1 p-1 w-full"
                name="userEmail"
                value={email}
                id="userEmail"
                onChange={(event) => this.onChangeHandler(event)}
              />
              <label htmlFor="userPassword" className="block">
                Password:
              </label>
              <input
                type="password"
                className="mt-1 mb-3 p-1 w-full"
                name="userPassword"
                value={password}
                id="userPassword"
                onChange={(event) => this.onChangeHandler(event)}
              />
              <button
                className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
                onClick={(event) => {
                  this.signInWithEmailAndPasswordHandler(
                    event,
                    email,
                    password
                  );
                }}
              >
                Sign in
              </button>
            </form>

            {error !== null && (
              <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LegalUpload;
