import React, { Component } from "react";
import firestore from "../../static/firebase";
import ReactModal from "react-modal";
import Cropper from "react-easy-crop";

//components
import NiceSelect from "../NiceSelect";
import Trademarks from "../Trademarks";
//styles
import "./index.css";

ReactModal.setAppElement("#root");

class Product extends Component {
  state = {
    showModal: false,
    crop: { x: 0, y: 0 },
    zoom: 1
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  changeStatus = (status, e) => {
    if (e) {
      e.preventDefault();
    }
    let { data, id, popCurrentProduct, imagesCollectionName } = this.props;

    if (data.status !== status) {
      popCurrentProduct(id);

      firestore
        .collection(imagesCollectionName)
        .doc(id)
        .update({ status: status });
    }
  };

  render() {
    let { data, id, imagesCollectionName } = this.props;
    let { showModal } = this.state;

    return (
      <tr>
        <td align="center">
          <b>{data.styleCode}</b>

          <div className="product">
            <img
              className="product-img"
              src={data.url}
              alt={data.styleCode}
              onClick={this.handleOpenModal}
              style={{ width: '75%'}}
            />
          </div>

          <ReactModal isOpen={showModal} onRequestClose={this.handleCloseModal}>
            <Cropper
              image={data.url}
              crop={this.state.crop}
              zoom={this.state.zoom}
              showGrid={false}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            />
          </ReactModal>
        </td>

        <td>
          <div className="button-group stacked">
            <button
              className="button primary small hollow"
              onClick={e => this.changeStatus("ONHOLD", e)}
            >
              {" "}
              Hold{" "}
            </button>
            <button
              className="button primary small hollow"
              onClick={e => this.changeStatus("APPROVED", e)}
            >
              {" "}
              Approve{" "}
            </button>
          </div>
        </td>

        <td>
          <div className="grid-x">
            <Trademarks
              data={data.trademarks}
              productId={id}
              trademarkFiles={this.props.trademarkFiles}
              trademarkScores={this.props.trademarkScores}
              imagesCollectionName={imagesCollectionName}
            />
          </div>
        </td>

        <td>
          <div>
            <NiceSelect
              activeNiceClass={data.niceClass}
              activeSubClass={data.subClass}
              productId={id}
              imagesCollectionName={imagesCollectionName}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default Product;
