import React from 'react'

// components
import ContainerCard from '../../components/ContainerCard'

const NotFound = () => {
	return (
		<ContainerCard
			cardSection="We appreciate your enthusiam, but unfortunately there isn't anything to be found here."
		/>
	)
}

export default NotFound
