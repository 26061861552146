import React, { Component } from "react";
import firestore, { storage } from "../../../static/firebase";
import Dropzone from "react-dropzone";
import LoaderSpinner from "../../../components/LoaderSpinner";

class LegalUpload extends Component {
  state = {
    uploading: false,
    errorMsg: "",
    successMsg: "",
    tmName: "",
    file: null
  };

  addTrademark = () => {
    const { tmName, file } = this.state;
    const reader = new FileReader();
    this.setState({ uploading: true });

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      let uint8Array = new Uint8Array(binaryStr);
      let metadata = {
        contentType: "image/jpeg"
      };

      storage
        .ref()
        .child(`trademarks/${tmName}.png`)
        .put(uint8Array, metadata)
        .then(() => {
          firestore
            .collection("trademarks")
            .add({
              name: tmName,
              path: `${tmName}.png`
            })
            .then(docRef => {
              this.setState({
                file: null,
                tmName: "",
                successMsg: "Trademark added!",
                uploading: false
              });
            });
        })
        .catch(error => {
          console.log(`Upload file error: ${error}`);
        });
    };

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  onDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    this.setState({ file });
  };

  render() {
    let { uploading, errorMsg, successMsg, tmName, file } = this.state;

    return (
      <div className="grid-container full">
        {!uploading ? (
          <div>
            {!file ? (
              <Dropzone
                disabled={file != null}
                className="dropzone"
                onDrop={files => this.onDrop(files)}
              >
                <div>
                  <div>Drop the trademark image here.</div>
                  <div>
                    <i>For best results upload a square image.</i>
                  </div>
                </div>
              </Dropzone>
            ) : (
              <img
                style={{ width: "35%" }}
                className="product-img"
                src={file.preview}
                alt="New trademark image."
              />
            )}
            <div className="grid-x">
              <div className="cell small-4" />
              <div className="cell small-4">
                <form>
                  <label>
                    Trademark name:
                    <div>
                      <i>Do not include spaces.</i>
                    </div>
                    <input
                      type="text"
                      value={tmName}
                      onChange={event => {
                        let tmName = event.target.value.replace(/\s/g, "");
                        this.setState({ tmName });
                      }}
                    ></input>
                  </label>
                  <button
                    className="button primary hollow"
                    disabled={!tmName.length || !file}
                    type="submit"
                    onClick={() => this.addTrademark()}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <LoaderSpinner />
        )}

        <div className="grid-x">
          <div className="cell small-5" />
          <div className="cell small-4">
            {errorMsg && (
              <div className="grid-x error-msg">
                <div className="callout alert">
                  <p>{errorMsg}</p>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="grid-x error-msg">
                <div className="callout success">
                  <p>{successMsg}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LegalUpload;
