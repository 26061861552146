import React from "react";
import Loader from "react-loader-spinner";

// styles
import "./index.css";

const LoaderSpinner = () => {
  return (
    <div className="grid-container loader text-center">
      <Loader
        type="Grid"
        color="#00BFFF"
        height="100"
        width="100"
      />
    </div>
  );
};

export default LoaderSpinner;
