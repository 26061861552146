import React, { Component } from 'react'
import ReactModal from 'react-modal'
import axios from 'axios';

// constants
import { TRADEMARK_SCORES, TRADEMARK_URLS } from '../../../static/constants'

// styles
import './index.css'

ReactModal.setAppElement('#root');

class SearchTrademark extends Component {
	
	//To store the file
	state = {
		displayFile: null,
		displayImage: null,
		displayImage2: null,
		displayImage3: null,
		showModal: false,
		data: {},
		data_comp: {}
	}
	
	sortTrademarkScores(trademarks) {
		let keysSorted = Object.keys(trademarks).sort(function(a,b){return trademarks[b]-trademarks[a]})
		let trademarksSorted = {}
		for (let key of keysSorted) {
			trademarksSorted[key] = trademarks[key]
		}
		return trademarksSorted
	}
	
	handleCloseModal = () => {
		this.refs.URLInput.value="";
		this.setState({ 
			showModal: false,
			displayImage: null,
			data: {},
			data_comp:{},
		});
	}
	
	imageURLHandler = event =>{
		this.setState({
			showModal: true,
			displayFile: event.target.value,
		})	
		const fd = new FormData();
		const fdd = new FormData();
		fd.append('url', event.target.value);
		fd.append('tag_group','gucci_trademarks');
		axios({
			url: 'https://cors-anywhere.herokuapp.com/http://virecognition.visenze.com/v1/image/recognize',
			method: 'post',
			headers: {'content-type': 'multipart/form-data'},
			data: fd,
			auth: {
				'username': '8127cba4a75364a084514fd26f91de45',
				'password': '5f63d4cce002398ad0d16907a8e25355'
			}
		})
		.then(res => {
			for(let i=0; i<res.data.result[0].objects[0].tags.length;i++){
				this.setState({
					data: {...this.state.data,[res.data.result[0].objects[0].tags[i].tag]: res.data.result[0].objects[0].tags[i].score}	
				})
			}
		})
		.catch(err => {
			console.log('recognize error:', err);
		});
		
		fdd.append('im_url', event.target.value);
		fdd.append('fl', "im_url");
		axios({
			url: 'https://cors-anywhere.herokuapp.com/http://visearch.visenze.com/uploadsearch',
			method: 'post',
			headers: {'content-type': 'multipart/form-data'},
			data: fdd,
			auth: {
				'username': '36e8e565ee3029ce009ce54f2ca0d9c3',
				'password': '178b55eaf979878f9393e38dcfb5d0ae'
			}
		})
		.then(res => {
			//for(let i=0; i<res.data.result[0].value_map.im_url.length;i++){
			this.setState({	
				displayImage: res.data.result[0].value_map.im_url,
				displayImage2: res.data.result[1].value_map.im_url,
				displayImage3: res.data.result[2].value_map.im_url,
			})
		})
		.catch(err => {
			console.log('search error:', err.response);
		});
	}

	createTrademarkList(){
		let { data } = this.state
		// console.log(data)
		let trademarks = Object.assign({}, TRADEMARK_SCORES, data)
		let trademarksSorted = this.sortTrademarkScores(trademarks)
		let entries = Object.entries(trademarksSorted)
		let trademarkList = []

		for (let [trademark, score] of entries) {
      trademarkList.push(
        <div className="cell small-1 tm-cell" key={trademark}>
          <div className="container">
            <img
              className={ score >= -1 ? 'image' : 'image faded' }
              width="70%"
              src={TRADEMARK_URLS[trademark]}
              alt={trademark}
            />

            {(score >= -1 && score < 0) && 
              <div>
                <div className="score reject">Removed</div>
              </div>
            }

            {(score > 0 && score <= 1) && 
              <div className="score">
                <div className="score accept">{Math.round(score.toFixed(2) * 100)}%</div>
              </div>
            }

            {(score === 2) && 
              <div className="score-added">
                <div className="score reject">Added</div>
              </div>
            }
            
          </div>
        </div>
      )
    }

		return trademarkList
	}
	
	render() {
		let { showModal } = this.state
		return (
			<div className="card-section">
				 <input type="text" onChange={this.imageURLHandler} placeholder="Paste an image URL here..." ref="URLInput"></input>
			<ReactModal isOpen={showModal}>
				<table className="unstriped">
					<thead>
					</thead>
					<h2>Competitor</h2>
					<tbody className="tbody">
						<tr>
							<td>
								<div className="product">
									<img
										className="product-img"
										src={this.state.displayFile}
										alt=""
									/>
								</div>
							</td>
							<td>
								<div className="grid-x">
									{this.createTrademarkList()}
								</div>
							</td>
						</tr>
					</tbody>
					</table>
					<h2>Gucci</h2>
					<img
						className="visual-img"
						src={this.state.displayImage}
						alt=""
					/>
					<img
						className="visual-img"
						src={this.state.displayImage2}
						alt=""
					/>
					<img
						className="visual-img"
						src={this.state.displayImage3}
						alt=""
					/>
				<div className="wrapper">
					<button onClick={this.handleCloseModal}>
						Go Back
					</button>	
				</div>			
			</ReactModal>
			</div>	
		)
	}
}

export default SearchTrademark
