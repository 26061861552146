import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
	apiKey: 'AIzaSyATZyJe7W4z9HRIbX36HlhhhhDx39j0atE',
	authDomain: 'gucci-dashboard.firebaseapp.com',
	databaseURL: 'https://gucci-dashboard.firebaseio.com',
	projectId: 'gucci-dashboard',
	storageBucket: 'gucci-dashboard.appspot.com',
	messagingSenderId: '611228503139'
}

firebase.initializeApp(config)

const firestore = firebase.firestore()
export const storage = firebase.storage()

export default firestore
