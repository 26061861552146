import React, { Component } from 'react'
import firestore from '../../static/firebase'
import Select from 'react-select'
import { NICE_CLASSES as niceClasses } from '../../static/constants'

class NiceSelect extends Component {
  state = {
    defaultNiceIndex: null,
    defaultSubIndex: null,
    selectedNice: null,
    selectedSub: null,
    subClasses: [],
  }

  handleNiceChange = selectedNice => {
    const { productId, imagesCollectionName } = this.props

    this.setState(
      {
        selectedNice,
        selectedSub: null,
        subClasses: selectedNice.sub_classes,
      },
      () => {
        firestore.collection(imagesCollectionName).doc(productId)
          .update({
            niceClass: selectedNice.label,
            subClass: ''
          })
      }
    );
  }

  handleSubChange = selectedSub => {
    const { productId, imagesCollectionName } = this.props

    this.setState(
      { selectedSub },
      () => {
        firestore.collection(imagesCollectionName).doc(productId)
          .update({ subClass: selectedSub.label })
      }
    );
  }

  componentDidMount = () => {
    const { activeNiceClass, activeSubClass } = this.props

    let selectedNice = niceClasses.find(element => element.label === activeNiceClass)
    let selectedSub
    if (selectedNice) {
      selectedSub = selectedNice.sub_classes.find(element => element.label === activeSubClass)
    }
    this.setState({
      selectedNice,
      selectedSub,
      subClasses: selectedNice ? selectedNice.sub_classes : []
    })
  }

  render() {
    const { selectedNice, selectedSub, subClasses } = this.state;

    return (
      <>
        Nice Class
        <Select
          value={selectedNice}
          onChange={this.handleNiceChange}
          options={niceClasses}
          styles={{
            control: base => ({
              ...base,
              minHeight: "45px",
              height: "45px"
            }),
            input: base => ({
              ...base,
              minHeight: "35px",
              height: "35px"
            })
          }}
        />

        Sub Class
        <Select
          value={selectedSub}
          onChange={this.handleSubChange}
          options={subClasses}
          isDisabled={subClasses.length === 0}
          styles={{
            control: base => ({
              ...base,
              minHeight: "45px",
              height: "45px"
            }),
            input: base => ({
              ...base,
              minHeight: "35px",
              height: "35px"
            })
          }}
        />

      </>
    );
  }
}

export default NiceSelect